import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout/old'
import PageBannerImage from '../components/PageBannerImage'
import PageBannerText from '../components/PageBannerText'
import ArticleCard from '../components/ArticleCard'

import ScienceGuy from '../images/science_guy.jpg'

import AgingHands from '../images/aging-hands.jpg'
import WomanBathing from '../images/woman-bathing.jpg'

const Science = () => (
	<Layout style={{ paddingBottom: '1rem' }}>
		<Helmet>
			<meta charSet="utf-8" />
			<title>The Science Behind Dead Sea: Learn more from Mineral Swim</title>
			<meta
				name="description"
				content="For thousands of years the hypersaline Dead Sea has been visited by
          people seeking its therapeutic and healing properties for the
          purpose of improving various skin conditions. Cleopatra is well
          documented for setting up the first beauty spas right on the shores
          of the Dead Sea. Since that time, the Dead Sea has become a major
          centre for health research and treatment. The mineral content of the
          Dead Sea, the very low content of pollens and other allergens in the
          atmosphere, the reduced ultraviolet component of solar radiation,
          and the higher atmospheric pressure at this great depth each have
          specific health effects."
			/>
			<meta
				name="keywords"
				content="dead sea, deadsea, science, dead sea science"
			/>
			<link rel="canonical" href="https://mineralswim.com/the-science/" />
		</Helmet>
		<PageBannerImage
			title={'The science of the Dead Sea'}
			img={ScienceGuy}
			imgPosition={'50%'}
		/>
		<PageBannerText
			title={'blank'}
			subtitle={
				<p>
					For thousands of years the hypersaline Dead Sea has been visited by
					people seeking its therapeutic and healing properties for the purpose
					of improving various skin conditions. Cleopatra is well documented for
					setting up the first beauty spas right on the shores of the Dead Sea.
					Since that time, the Dead Sea has become a major centre for health
					research and treatment. The mineral content of the Dead Sea, the very
					low content of pollens and other allergens in the atmosphere, the
					reduced ultraviolet component of solar radiation, and the higher
					atmospheric pressure at this great depth each have specific health
					effects.
					<br />
					<br />
					Today people all over the world can enjoy the benefits of using the
					high concentration of minerals from the Dead Sea. Discover the
					research into the well-being benefits of the Dead Sea.
				</p>
			}
		/>
		<div style={{ padding: '0 1rem' }}>
			<div className="columns is-multiline">
				<ArticleCard
					title="The effects of Dead Sea minerals"
					slug="downloads/dead-sea-minerals-gerantology-dead-sea-study.pdf"
					type="journal"
					excerpt="The aging process and its characterization in keratinocytes have not been studied until now..."
					imgSrc={AgingHands}
					imgAlt="The effects of Dead Sea minerals on aged hands"
				/>
				<ArticleCard
					title="Bathing in a magnesium-rich Dead Sea salt solution"
					slug="downloads/study-bathing-in-a-magnesium-rich-dead-sea-salt-solution.pdf"
					type="journal"
					excerpt="Improves skin barrier function, enhances skin hydration, and reduces inflammation..."
					imgSrc={WomanBathing}
					imgAlt="Woman bathing in a magnesium-rich Dead Sea salt solution"
				/>
			</div>
		</div>
	</Layout>
)

export default Science
