import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ArticleCard = (props) => {
	const { title, slug, type, excerpt, imgSrc, imgAlt } = props
	return (
		<div className="article-card column is-one-quarter">
			<div className="card">
				<div className="card-image">
					{type === 'journal' ? (
						<a href={`/` + slug} className="view-text" download>
							<h2 className="text">View {type}</h2>
						</a>
					) : (
						<Link className="view-text" to={`/` + slug}>
							)<h2 className="text">View {type}</h2>
						</Link>
					)}
					<figure className="image is-16by9">
						<img src={imgSrc} alt={imgAlt} />
					</figure>
				</div>
				<div className="card-content">
					<h5 className="__type">{type}</h5>
					{type === 'journal' ? (
						<a href={`/` + slug} className="view-link" download>
							<h4 className="__title">{title}</h4>
						</a>
					) : (
						<Link className="view-link" to={`/` + slug}>
							<h4 className="__title">{title}</h4>
						</Link>
					)}
					<div className="content">{excerpt}</div>
				</div>
			</div>
		</div>
	)
}

ArticleCard.propTypes = {
	title: PropTypes.string,
	slug: PropTypes.string,
	type: PropTypes.string,
	excerpt: PropTypes.string,
	imgSrc: PropTypes.string,
	imgAlt: PropTypes.string,
}

export default ArticleCard
